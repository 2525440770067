import {
    http
} from '@/http/axios.js'


// 列表
export function getList(params) {
    return http({
        url: `/company/basic/article/category/list`,
        method: 'get',
        params
    })
}


export function removeCategory(data) {
    return http({
        url: `/company/basic/article/category/del`,
        method: 'post',
        data
    })
}
export function addCategory(data) {
    return http({
        url: `/company/basic/article/category/add`,
        method: 'post',
        data
    })
}

export function editCategory(data) {
    return http({
        url: `/company/basic/article/category/edit`,
        method: 'post',
        data
    })
}

export function detail(id) {
    return http({
        url: `/company/basic/article/category/detail`,
        method: 'get',
        params: {id : id}
    })
}
